<template>
  <div class="app-container">
    <folder-view :encryptionStr="encryptionStr" :type="type"></folder-view>
  </div>
</template>
<script>
import folderView from '@/components/folderView/index.vue'
export default {
  components: { folderView },
  data() {
    return {
      encryptionStr: '',
      type: 'my'
    }
  },
  mounted() {
    const matchStr = this.$route.params.pathMatch
    if (this.$route.fullPath.indexOf('share') !== -1) {
      // 与我分享
      this.encryptionStr = matchStr
      this.type = 'sharedme'
    } else if (this.$route.fullPath.indexOf('cooperation') !== -1) {
      // 与我协作
      this.encryptionStr = this.$route.params.pathMatch
      this.type = 'cooperation'
    } else if (this.$route.fullPath.indexOf('favorites') !== -1) {
      // 收藏
      this.encryptionStr = this.$route.params.pathMatch
      this.type = 'favorites'
    } else {
      // 我的空间
      this.encryptionStr = this.$route.params.pathMatch
      this.type = 'my'
    }
  }
  // watch:{
  //   $route(to,from){
  //     console.log(11111, to.path)
  //   }
  // },
}
</script>
